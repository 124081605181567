import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Files from 'react-files';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import Button from 'components/Button';
import { getLocaleMap } from 'utils/locales';

const ReportFileUploader = ({ name, value, support_multiple_files, accept_file_types, handleChange, setFieldValue, ...props }) => {
  const [lm, setLm] = useState({});
  useEffect(() => {
    getLocaleMap(['labels_no_file_selected', 'labels_file_selected', 'add_file_button', 'clear_file_button', 'select_target_file'])
      .then((response) => {
        setLm(response);
      });
  }, []);
  const [currentFiles, setCurrentFiles] = useState([]);
  const addFiles = (files) => {
    if (support_multiple_files) {
      setCurrentFiles(currentFiles.concat(files));
    } else {
      setCurrentFiles([files[0]]);
    }
  };
  const clearFiles = () => {
    currentFiles.length = 0;
    setCurrentFiles([]);
  };
  useEffect(() => {
    setFieldValue(name, currentFiles);
  }, [currentFiles, name, setFieldValue]);

  let labelText = lm.labels_no_file_selected;
  let popupText = labelText;
  const fileCount = currentFiles.length;
  if (fileCount === 1) {
    const fileName = currentFiles[0].name;
    labelText = fileName;
    popupText = fileName;
  } else {
    labelText = `${fileCount} ${lm.labels_file_selected}`;
    const POPUP_MAX_ROW_COUNT = 25;
    const isWithinMaxRowCount = fileCount <= POPUP_MAX_ROW_COUNT;
    const files = isWithinMaxRowCount ? currentFiles : currentFiles.slice(0, POPUP_MAX_ROW_COUNT);
    popupText = files.map((currentFile) => currentFile.file_name).join('\n');
    if (!isWithinMaxRowCount) {
      popupText += '\n...';
    }
  }

  const commonButtonProps = {
    variant: 'text',
    size: 'medium',
    font_size: 16,
    color: '#fff',
    sx: {
      // backgroundImage: 'url(https://ams.joyaethercloud.com/ams-server/contents/files/1712046662671-Action_Button_bg.png)',
      backgroundImage: 'url(http://ec2-18-166-39-69.ap-east-1.compute.amazonaws.com/ams-server/contents/files/1712046662671-Action_Button_bg.png)',
      backgroundSize: '100% 100%',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
  };
  return (
    <Grid container spacing={1}>
      <Grid item>
        <Files
          multiple={false}
          accepts={accept_file_types}
          onChange={addFiles}
          clickable
        >
          <Grid container justifyContent="center" alignItems="center">
            <Grid item marginRight="2rem">
              <Typography title={popupText} fontSize="16px">
                {labelText}
              </Typography>
            </Grid>

            <Grid item>
              <Button fullWidth borderRadius="0px" {...commonButtonProps}>
                {lm.select_target_file}
              </Button>
            </Grid>

          </Grid>
        </Files>
      </Grid>
      {currentFiles.length > 0 && (
        <Grid item>
          <Button fullWidth borderRadius="0px" onClick={clearFiles} {...commonButtonProps}>
            {lm.clear_file_button}
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

ReportFileUploader.propTypes = {
  value: PropTypes.any,
  name: PropTypes.string,
  support_multiple_files: PropTypes.bool,
  accept_file_types: PropTypes.arrayOf(PropTypes.string),
  setFieldValue: PropTypes.func,
  handleChange: PropTypes.func,
};

export default ReportFileUploader;
